import React, { ReactNode, useState, useCallback } from 'react';
import Text from '../text';
import { SizeEnum, WeightEnum } from '../../types';
import SizeIconClassic from './icons/SizeIconClassic';
import SizeIconLarge from './icons/SizeIconLarge';
import SizeIconXL from './icons/SizeIconXL';
import './index.less';

type SizeType = 'CLASSIC' | 'LARGE' | 'EXTRA_LARGE';

interface ApplicationTitleProp {
  title: string;
  notifications?: number;
  logoUrl?: string;
  children?: ReactNode;
  onSizeChange?: (size: SizeType) => void;
  initialSize?: SizeType;
  availableSizes?: SizeType[];
}

const sizeIcons: Record<SizeType, React.FC<React.SVGProps<SVGSVGElement>>> = {
  CLASSIC: SizeIconClassic,
  LARGE: SizeIconLarge,
  EXTRA_LARGE: SizeIconXL,
};

export default function ApplicationTitle({
  title,
  notifications,
  logoUrl,
  children,
  onSizeChange,
  initialSize = 'CLASSIC',
  availableSizes,
}: ApplicationTitleProp) {
  const [currentSize, setCurrentSize] = useState<SizeType>(initialSize);

  const handleSizeChange = useCallback(
    (size: SizeType) => {
      setCurrentSize(size);
      onSizeChange?.(size);
    },
    [onSizeChange],
  );

  const toggleAppSize = useCallback(() => {
    if (!availableSizes || availableSizes.length === 0) return;

    const currentIndex = availableSizes.indexOf(currentSize);
    const nextIndex = (currentIndex + 1) % availableSizes.length;
    const nextSize = availableSizes[nextIndex];

    handleSizeChange(nextSize);
  }, [availableSizes, currentSize, handleSizeChange]);

  return (
    <header className="application-title">
      <Text className="label" size={SizeEnum['14px']}>
        <h1 className="no-margin no-padding inline-block">
          <Text weight={WeightEnum.semibold} inline>
            {title}
          </Text>
        </h1>
        &nbsp;
        {notifications !== undefined && `(${notifications})`}
      </Text>
      {children}
      {logoUrl ? <img src={logoUrl} className="insurer-logo" alt="data connection logo" /> : null}
      {availableSizes && availableSizes.length > 0 && (
        <div className="size-buttons-container">
          <button onClick={toggleAppSize} className="size-button">
            <div className="icons-container">
              {availableSizes.map((size) => {
                const Icon = sizeIcons[size];
                return (
                  <Icon
                    key={size}
                    className={`size-icon ${currentSize === size ? 'active' : ''}`}
                  />
                );
              })}
            </div>
          </button>
        </div>
      )}
    </header>
  );
}
