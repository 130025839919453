import { NonEmptyArray } from '@getvim-os/types';
import { EHRResource } from '@getvim/vim-os-api';
import { EHR } from '@getvim/vim-os-sdk-api';
import { Nullable } from 'vitest';
import ResourceUpdateBuilder from './resourceUpdateBuilder';

type UpdatableEncounter = EHR.UpdateEncounterParams;

export default class EncounterUpdateBuilder extends ResourceUpdateBuilder<
  typeof EHRResource.encounter
> {
  protected readonly ehrResource = EHRResource.encounter;

  public updateSubjective(subjective: NonNullable<UpdatableEncounter['subjective']>) {
    return this.setObjectField('subjective', subjective);
  }
  public updateObjective(objective: NonNullable<UpdatableEncounter['objective']>) {
    return this.setObjectField('objective', objective);
  }
  public updateAssessment(assessment: NonNullable<UpdatableEncounter['assessment']>) {
    return this.setObjectField('assessments', assessment);
  }

  public updatePlan(
    plan: Nullable<UpdatableEncounter['plan'] & UpdatableEncounter['billingInformation']>,
  ) {
    const { procedureCodes, ...restPlan } = plan || {};

    return this.setObjectField('plans', {
      ...restPlan,
      procedureCodes: procedureCodes?.map((procedureCode) => ({
        code: procedureCode.code,
        name: procedureCode.description,
      })) as NonEmptyArray<EHR.UpdatableProcedures>,
    });
  }
  public updatePatientInstructions(
    patientInstructions: NonNullable<UpdatableEncounter['patientInstructions']>,
  ) {
    return this.setObjectField('patientInstructions', patientInstructions);
  }
}
