import React, { useState, useEffect } from 'react';
import { organizationManager } from './state';
import { useCdeConfig } from './hooks/useCdeConfig';

interface AsyncContentWrapperOldProps {
  promises: Promise<any>[];
  loader: JSX.Element;
}

const AsyncContentWrapperOld: React.FC<AsyncContentWrapperOldProps> = ({
  promises,
  loader,
  children,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [_, setResolvedData] = useState<any | null>(null);
  const { organization } = useCdeConfig();
  //organization is undefined in case of runtime
  if (organization) {
    organizationManager.set({ organizationId: organization.identifiers?.id });
  }

  useEffect(() => {
    const fetchData = async () => {
      const data = await Promise.all(promises);
      setResolvedData(data);
      setIsLoading(false);
    };

    fetchData();
  }, [promises]);

  if (isLoading) {
    return loader;
  }

  return <>{children}</>;
};

export default AsyncContentWrapperOld;
