import { AppToOSMessageTypes } from '@getvim/vim-os-api';
import { OsCommunicator } from './osCommunicator';
import { EHR } from '@getvim/vim-os-sdk-api';

export class PutChartRequest {
  constructor(private osCommunicator: OsCommunicator) {}

  putChartRetrievalRequest = async (
    encounterId: string,
    patientId: string,
    timeout?: number,
  ): Promise<EHR.PutChartRetrievalRequestResponse> => {
    return await this.osCommunicator.sendAwaitedMessage<EHR.PutChartRetrievalRequestResponse>(
      {
        type: AppToOSMessageTypes.PUT_CHART_REQUEST,
        payload: {
          encounterId,
          patientId,
        },
      },
      timeout,
    );
  };
}
