const scriptUrl = (document.currentScript as HTMLScriptElement)?.src;

const versionPathPattern = /\/v(\d+(\.\d+|\.\w+){2})\//;

export function getExternalSdkVersion(url: string | undefined) {
  if (!url) {
    return;
  }

  try {
    const version = new URL(url).searchParams.get('version');

    if (version) {
      return version;
    }

    const versionMatch = url.match(versionPathPattern);

    if (versionMatch) {
      return versionMatch[1];
    }
  } catch {
    return undefined;
  }
}

export type SdkMetadata = {
  vimVersion?: string;
  version?: string;
  sdkScriptUrl?: string;
  externalSDKVersion?: string;
  isUsingNonVersionedSdk?: boolean;
};

// get metadata on how sdk is running. it can be one of the three setups
// 1. bundled with vim internal app.
// 2. injected via NPM
// 3. injected as script tag
export function getSdkMetadata(): SdkMetadata {
  const metadata: SdkMetadata = {
    vimVersion:
      (window as any).$vim_environment?.vim_version ?? (import.meta as any).env?.VIM_VERSION,
    version: (window as any).$vim_environment?.version ?? (import.meta as any).env?.BKMD_VERSION,
    sdkScriptUrl: scriptUrl,
    externalSDKVersion: getExternalSdkVersion(scriptUrl),
  };

  return metadata;
}
