export enum SizeEnum {
  'default' = '',
  '12px' = 'text-12',
  '13px' = 'text-13',
  '14px' = 'text-14',
  '16px' = 'text-16',
  '18px' = 'text-18',
  '22px' = 'text-22',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '24px' = 'text-24',
}

export enum ColorNameEnum {
  dark = 'text-dark',
  danger = 'text-danger',
  extraDark = 'text-extra-dark',
  ultraDark = 'text-ultra-dark',
  light = 'text-faded',
  theme = 'theme',
  themeSecondary = 'themeSecondary',
  themeLink = 'text-theme-link',
}

export enum AlignEnum {
  center = 'text-center',
  left = 'text-left',
  right = 'text-right',
}

export enum WeightEnum {
  light = 'text-light', // Weight 100
  normal = 'text-normal', // Weight 400
  medium = 'text-medium', // Weight 500
  semibold = 'text-semibold', // Weight 600
  bold = 'text-strong', // Weight 700
  black = 'text-black', // Weight 900
}

export enum TextTransformEnum {
  capitalize = 'text-capitalize',
  upperCase = 'text-uppercase',
  lowerCase = 'text-lowercase',
}
