import { ChipPropsWrapper, STATUS_CHIP_ENUM } from './types';
import FailedIcon from './icons/FailedIcon.svg';
import NoDataIcon from './icons/NoDataIcon.svg';
import SharedIcon from './icons/SharedIcon.svg';
import ProcessingIcon from './icons/ProcessingIcon.svg';
import RejectedIcon from './icons/RejectedIcon.svg';

export const WIDGET_ID = 'cde';
export const DATA_EXCHANGE_ADMIN_ROLE = 'Data Exchange Admin';

/**
 * Sleeps
 */
const ONE_MINUTE_IN_MS = 60 * 1000;
export const MS_SLEEP_BETWEEN_TAKE_JOB = ONE_MINUTE_IN_MS;
export const MS_SLEEP_BETWEEN_FIND_PATIENT_REQUESTS = 15 * ONE_MINUTE_IN_MS;
export const MS_COOLDOWN_BOTTOM_LIMIT = 5000;
export const MS_COOLDOWN_UPPER_LIMIT = 10_000;

/**
 * Error Messages
 */
export const NO_PATIENT_ERROR_MESSAGE = "No patient was found in EHR given the job's criteria";
export const FAILED_TO_PRINT_ENCOUNTER_ERROR_MESSAGE = 'Failed to print encounter';
export const DATA_RESTRICTED_ERROR_MESSAGE =
  'Data is Restricted - Patient is defined as self-pay or ROI';
export const FIND_PATIENT_UNEXPECTED_ISSUE = 'FIND_PATIENT failed for unexpected error';
export const GET_PATIENT_UNEXPECTED_ISSUE =
  'GET_PATIENT didnt return a patient for a known patientId';
export const ENCOUNTER_NOT_LOCKED_ERROR_MESSAGE = 'Encounter is unlocked';
export const INVALID_WORKER = 'Invalid worker';

/**
 * Table
 */
export const TABLE_PAGE_SIZE = 20;

/**
 * Status Tooltips
 */
export const CHIP_DATA: Record<STATUS_CHIP_ENUM, ChipPropsWrapper> = {
  processing: {
    text: 'Processing',
    tooltipText:
      'The patient relevant encounter documentation chart are being prepared to be shared with the data requester.',
    leftIcon: ProcessingIcon,
    bgColor: '#10ABE430',
    info: '',
  },
  rejected: {
    text: 'Rejected',
    tooltipText:
      'The patient relevant encounter documentation will not be shared with the data requester as the provider rejected the request.',
    leftIcon: RejectedIcon,
    bgColor: '#FFC30430',
    info: '',
  },
  failed: {
    text: 'Failed',
    tooltipText: 'The patient relevant encounter documentation chart extraction process failed.',
    leftIcon: FailedIcon,
    bgColor: '#FD717130',
    info: '',
  },
  shared: {
    text: 'Shared',
    tooltipText:
      'The patient relevant encounter documentation have been shared with the data requester.',
    leftIcon: SharedIcon,
    bgColor: '#0DC99E30',
    info: '',
  },
  no_data: {
    text: 'N/A',
    tooltipText: 'Chart extraction failed due to no encounters found in the requested data range.',
    leftIcon: NoDataIcon,
    bgColor: '#32333C26',
    info: 'No encounters found',
  },
  patient_doesnt_exist: {
    text: 'N/A',
    tooltipText: 'Chart extraction failed due to patient not found in the EHR.',
    leftIcon: NoDataIcon,
    bgColor: '#32333C26',
    info: 'Patient not found',
  },
  expired: {
    text: 'Failed',
    tooltipText: 'Data extraction failed: Maximum time limit exceeded',
    leftIcon: FailedIcon,
    bgColor: '#FD717130',
    info: 'Request time exceeded',
  },
  data_restricted: {
    text: 'Failed',
    tooltipText: 'Auto rejected based on EHR indication for self-pay or data sharing restrictions',
    leftIcon: FailedIcon,
    bgColor: '#FD717130',
    info: 'Sharing restricted',
  },
  encounter_not_locked_failed: {
    text: 'Processing',
    tooltipText:
      'Chart extraction failed due to no locked encounters found in the requested date range.',
    leftIcon: ProcessingIcon,
    bgColor: '#10ABE430',
    info: 'Encounter has not been locked',
  },
  encounter_not_locked_expired: {
    text: 'Failed',
    tooltipText:
      'Chart extraction failed due to no locked encounters found in the requested date range.',
    leftIcon: FailedIcon,
    bgColor: '#32333C26',
    info: 'Encounter has not been locked',
  },
};
