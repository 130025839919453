import {
  AppToOSMessageTypes,
  EHRResource,
  UpdatableEhrFields,
  UpdatableEhrState,
  WRITEBACK_TIMEOUT,
} from '@getvim/vim-os-api';
import { ErrorMessageResponseType } from '@getvim/vim-os-sdk-api';
import { OsCommunicator } from '..';
import { SdkLogger } from '../../sdkLogger';
import { IResourceUpdateBuilder } from '../../types/ehr-updates/builders';

type RUpdatableFields = Required<UpdatableEhrFields>;

export default abstract class ResourceUpdateBuilder<T extends EHRResource>
  implements IResourceUpdateBuilder
{
  protected abstract readonly ehrResource: T;

  #dataToUpdate: UpdatableEhrState<T> = {};
  #dataToAdd: UpdatableEhrState<T> = {};

  constructor(private osCommunicator: OsCommunicator, private logger?: SdkLogger) {}

  protected setObjectField<
    F extends string & keyof RUpdatableFields[T] & keyof UpdatableEhrState<T>,
  >(field: F, value: UpdatableEhrState<T>[F]) {
    const noNilValue =
      value &&
      Object.keys(value).reduce((acc, key) => {
        if (value[key] !== undefined && value[key] !== null) {
          acc[key] = value[key];
        }
        return acc;
      }, {});

    this.#dataToUpdate[field] = noNilValue;

    return this;
  }
  protected setField<F extends string & keyof RUpdatableFields[T] & keyof UpdatableEhrState<T>>(
    field: F,
    value: UpdatableEhrState<T>[F],
  ) {
    this.#dataToUpdate[field] = value;

    return this;
  }
  protected appendField<F extends string & keyof RUpdatableFields[T] & keyof UpdatableEhrState<T>>(
    field: F,
    value: UpdatableEhrState<T>[F],
  ) {
    this.#dataToAdd[field] = value;

    return this;
  }

  protected getField<F extends keyof UpdatableEhrState<T>>(field: F): UpdatableEhrState<T>[F] {
    return this.#dataToUpdate[field];
  }

  public async commit() {
    try {
      if (
        Object.keys(this.#dataToUpdate).length === 0 &&
        Object.keys(this.#dataToAdd).length === 0
      ) {
        this.logger?.error('No fields were specified in the update request', {
          resource: this.ehrResource,
        });
        throw {
          type: ErrorMessageResponseType.validation_error,
          data: `The update of ${this.ehrResource} failed due to preconditions. No fields were specified in the update request.`,
        };
      }

      const res = await this.osCommunicator.sendAwaitedMessage(
        {
          type: AppToOSMessageTypes.UPDATE_RESOURCE,
          payload: {
            resource: this.ehrResource,
            dataToUpdate: this.#dataToUpdate,
            dataToAdd: this.#dataToAdd,
          },
        },
        WRITEBACK_TIMEOUT,
      );
      if (res && typeof res === 'object' && Object.values(res).includes(false)) {
        this.logger?.error('Update request returned some false fields', {
          resource: this.ehrResource,
          dataToUpdate: this.#dataToUpdate,
          dataToAdd: this.#dataToAdd,
          res,
        });
        throw {
          type: ErrorMessageResponseType.internal_error,
          data: {
            reason: 'Some fields failed to update',
            updateResult: res,
          },
        };
      }
      return res;
    } finally {
      this.#dataToAdd = {};
      this.#dataToUpdate = {};
    }
  }
}
