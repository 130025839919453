import React from 'react';

const SizeIconClassic: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 1.125C7 0.50368 7.50368 0 8.125 0H12.5C13.1213 0 13.625 0.50368 13.625 1.125V9.875C13.625 10.4963 13.1213 11 12.5 11H8.125C7.50368 11 7 10.4963 7 9.875V1.125ZM8.125 1C8.05596 1 8 1.05596 8 1.125V9.875C8 9.94404 8.05596 10 8.125 10H12.5C12.569 10 12.625 9.94404 12.625 9.875V1.125C12.625 1.05596 12.569 1 12.5 1H8.125Z"
      fill="#001C36"
    />
    <mask
      id="mask0_6887_3602"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="8"
      height="11"
    >
      <rect width="8" height="11" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_6887_3602)">
      <rect
        x="0.5"
        y="0.5"
        width="10"
        height="10"
        rx="0.5"
        stroke="#001C36"
        strokeLinejoin="bevel"
        strokeDasharray="2 1"
      />
    </g>
  </svg>
);

export default SizeIconClassic;
