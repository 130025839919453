import React from 'react';

const SizeIconXL: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="15"
    height="11"
    viewBox="0 0 15 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1.125C0 0.50368 0.50368 0 1.125 0H13.25C13.8713 0 14.375 0.50368 14.375 1.125V9.875C14.375 10.4963 13.8713 11 13.25 11H1.125C0.50368 11 0 10.4963 0 9.875V1.125ZM1.125 1C1.05596 1 1 1.05596 1 1.125V9.875C1 9.94404 1.05596 10 1.125 10H13.25C13.319 10 13.375 9.94404 13.375 9.875V1.125C13.375 1.05596 13.319 1 13.25 1H1.125Z"
      fill="#001C36"
    />
  </svg>
);

export default SizeIconXL;
