import { MessageResultError } from '@getvim-os/errors';
import { ApplicationManifest } from '@getvim-os/types';
import {
  AppToOsMessageData,
  AppToOSMessageTypes,
  HandshakePayload,
  osMessageResponseTypeSchema,
} from '@getvim/vim-os-api';
import { ErrorMessageResponseType } from '@getvim/vim-os-sdk-api';
import get from 'lodash-es/get';
import NotAllowedError from '../errors/notAllowedError';
import { SdkLogger } from '../sdkLogger';

const MESSAGE_RESPONSE_TIMEOUT = 30_000; // 30 seconds

export const PermissionMap: Partial<Record<keyof typeof AppToOSMessageTypes, string>> = {
  [AppToOSMessageTypes.DISPLAY_PUSH_NOTIFICATION]: 'UI.capabilities.popupNotification',
  [AppToOSMessageTypes.POP_UP]: 'UI.capabilities.autoPopup',
  [AppToOSMessageTypes.SET_NOTIFICATIONS_INDICATOR]: 'UI.capabilities.counterNotificationLabel',
  [AppToOSMessageTypes.CLEAR_NOTIFICATIONS_INDICATOR]: 'UI.capabilities.counterNotificationLabel',
};

export class OsCommunicator {
  private manifestSupport: ApplicationManifest;
  private messagePort: MessagePort;
  protected permissionMap: Partial<Record<AppToOSMessageTypes, string>>;
  constructor(
    handshakePayload: HandshakePayload,
    osMessageChannel: MessageChannel,
    private logger?: SdkLogger,
    private allowedMessageTypes = Object.keys(AppToOSMessageTypes),
  ) {
    this.manifestSupport = handshakePayload.manifestSupport;
    this.messagePort = osMessageChannel.port1;
    this.permissionMap = PermissionMap;
  }

  public sendMessageToOS(message: AppToOsMessageData) {
    this.verifyMessageAllowed(message);
    return this.messagePort.postMessage(message);
  }

  public async sendAwaitedMessage<T = unknown>(
    data: AppToOsMessageData,
    timeout = MESSAGE_RESPONSE_TIMEOUT,
  ): Promise<T> {
    this.verifyMessageAllowed(data);
    const { type } = data;
    return await new Promise<T>((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        this.logger?.error('OsCommunicator timed out waiting for response from Vim-OS', { data });
        reject({
          type: ErrorMessageResponseType.internal_error,
          data: `Did not receive response from Vim-OS for ${timeout}`,
          code: 500,
        });
      }, timeout);

      const messageChannel = new MessageChannel();
      messageChannel.port1.addEventListener('message', (event) => {
        clearTimeout(timeoutId);
        const result = event.data;

        if (result.error) {
          if (osMessageResponseTypeSchema.safeParse(result.response?.type).success) {
            reject(result.response);
          } else {
            reject({
              type: ErrorMessageResponseType.internal_error,
              data: new MessageResultError(type, result.response),
              code: 500,
            });
          }
        } else {
          resolve(result.response as T);
        }
        messageChannel.port1.close();
      });
      messageChannel.port1.start();
      this.messagePort.postMessage(data, [messageChannel.port2]);
    });
  }

  private verifyMessageAllowed(message: AppToOsMessageData) {
    if (!this.allowedMessageTypes.includes(message.type)) {
      this.logger?.error('OsCommunicator received message of unknown type', { message });
      throw new NotAllowedError(message.type);
    }
    const permission = this.permissionMap[message.type];
    if (permission && !get(this.manifestSupport, permission)) {
      this.logger?.error('OsCommunicator received message without permission', { message });
      throw new NotAllowedError(message.type);
    }
  }
}
