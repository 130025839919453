import { ActionTypes, EncounterTypeEnum, ReleaseOfInformation } from '@getvim-os/types';
import { Response } from '../../../../../../packages/os/types/src/actions/patient/encounters';
import { adapterActionApi } from '../../../api/adapter';
import { ContextedLogger, getLogger } from '../../../components/logger';
import {
  DATA_RESTRICTED_ERROR_MESSAGE,
  ENCOUNTER_NOT_LOCKED_ERROR_MESSAGE,
  FAILED_TO_PRINT_ENCOUNTER_ERROR_MESSAGE,
} from '../../../consts';
import { featureFlagsClient } from '../../../services';
import { jobManager } from '../../../state';
import { CdeJobType, FileExtension, FileMetadata, FileResult } from '../../../types';
import { createJsonFile } from '../../file/createJsonFile';
import { writeToS3 } from '../../s3';
import { convertMetadata, generateBlobFromHtmlString, getFileName } from '../metadataFile';
import { PatientEncountersHandlerParams, PrintEncountersError } from '../types';
import { generateLabsAndVitalsFile } from '../labs-and-vitals/logic';
import { ActionNames } from '@getvim/internal-vim-os-sdk/types';

export const patientEncountersHandler = async ({
  ehrPatient,
}: PatientEncountersHandlerParams): Promise<FileResult[]> => {
  const {
    shouldAllowUnlockedEncounterFF,
    shouldReportEncountersNotLockedToDataSourceFF,
    shouldUseAdditionalJobFiltersFF,
  } = await fetchFeatureFlags();

  const {
    type,
    requester,
    jobUUID,
    fromDate,
    untilDate,
    encounterId,
    patient: { pseudonymizedId },
    additionalFilters,
  } = jobManager.getAll();

  const logger = getLogger({
    scope: `patientEncountersHandler:${jobUUID}_${fromDate}-${untilDate}`,
  });

  const { patientId, selfPay, releaseOfInformation } = ehrPatient;

  handlePatientPrivacy(selfPay, releaseOfInformation, logger, pseudonymizedId, ehrPatient, jobUUID);

  const encounters: Awaited<ReturnType<typeof adapterActionApi.getPatientEncounters>> =
    await getRelevantEncountersIds(
      type,
      patientId,
      encounterId,
      fromDate,
      untilDate,
      logger,
      jobUUID,
    );

  const encounterDataPromises = encounters.map(({ id: encounterId }) =>
    adapterActionApi.getEncounterData({ encounterId, patientId }),
  );

  let encountersData: Awaited<ActionTypes.EncounterAction.Response>[];
  try {
    encountersData = await Promise.all(encounterDataPromises);
    logger.info('Got encounters data', { fromDate, untilDate, patientId });
  } catch (error) {
    logger.error('Failed to get encounters data', {
      error,
      fromDate,
      untilDate,
      patientId,
      jobUUID,
    });
    throw error;
  }

  const fileResults: FileResult[] = [];
  const printEncountersErrors: PrintEncountersError[] = [];

  for (const { id: encounterId } of encounters) {
    const encounterData: Awaited<ActionTypes.EncounterAction.Response> | undefined =
      encountersData.find((enc) => enc.encounterId === encounterId);

    if (!encounterData) {
      throw new Error(
        `Missing encounter information required for ${ActionNames.PRINT_ENCOUNTER}, encounterId: ${encounterId}, patientId: ${patientId}`,
      );
    }

    const shouldIncludeEncounter =
      !shouldUseAdditionalJobFiltersFF ||
      !additionalFilters?.shouldNotIncludeTelephoneEncounters ||
      (additionalFilters?.shouldNotIncludeTelephoneEncounters &&
        encounterData.encounterType !== EncounterTypeEnum.Telephone);

    if (!shouldIncludeEncounter) {
      logger.info('Skipping encounter due to additional filters', {
        encounterId,
        encounterType: encounterData.encounterType,
      });
      continue;
    }

    if (!encounterData.isLocked && !shouldAllowUnlockedEncounterFF) {
      logger.info('Encounter is unlocked', {
        encounterId,
        fromDate,
        untilDate,
        patientId,
        jobUUID,
      });
      printEncountersErrors.push([encounterId, ENCOUNTER_NOT_LOCKED_ERROR_MESSAGE]);
      continue;
    }

    if (!encounterData.provider) {
      logger.info(
        'Missing provider information in encounter data, will skip writing provider to metadata json',
        {
          encounterId,
          patientId,
          jobUUID,
        },
      );
    }

    logger.info('Found encounter for criteria', { encounterId, patientId, noPHI: true, jobUUID });

    try {
      const files = await handlePrintHTMLAndJSONFiles(
        encounterData,
        encounterId,
        requester,
        pseudonymizedId,
        patientId,
        ehrPatient,
        logger,
        jobUUID,
        fileResults,
      );

      await handleUploadEncounterPrintedFilesToS3(
        jobUUID,
        files,
        fileResults,
        logger,
        fromDate,
        untilDate,
        encounterId,
        patientId,
      );
    } catch (error: any) {
      logger.warning(`Encounter had general error when trying to print encounter`, {
        error,
        jobUUID,
      });

      printEncountersErrors.push([encounterId, error?.message ?? error]);
    }
  }

  handlePrintEncountersErrors(
    printEncountersErrors,
    encounters,
    fileResults,
    logger,
    fromDate,
    untilDate,
    patientId,
    jobUUID,
    type,
    shouldReportEncountersNotLockedToDataSourceFF,
  );

  return fileResults;
};

/**
 * Retrieves the relevant encounter IDs based on the job type and criteria.
 * @param type - The type of the job.
 * @param patientId - The ID of the patient.
 * @param encounterId - The ID of the encounter - relevant only for single encounter flow
 * @param fromDate - The start date of the encounters.
 * @param untilDate - The end date of the encounters.
 * @param logger - The logger instance.
 * @param jobUUID - The UUID of the job.
 * @returns A promise that resolves to an array of encounter IDs.
 */
async function getRelevantEncountersIds(
  type: CdeJobType,
  patientId: string,
  encounterId: string | undefined,
  fromDate: any,
  untilDate: any,
  logger: ContextedLogger,
  jobUUID: string,
): Promise<Awaited<ReturnType<typeof adapterActionApi.getPatientEncounters>>> {
  let encounters: Awaited<ReturnType<typeof adapterActionApi.getPatientEncounters>>;

  try {
    if (type == CdeJobType.getPatientEncounters) {
      encounters = await adapterActionApi.getPatientEncounters({ patientId });
      logger.info('Got encounters', { fromDate, untilDate, count: encounters.length, jobUUID });
      if (!encounters.length) return [];
    } else {
      encounters = [{ id: encounterId ?? '' }];
      logger.info('Single Encounter job flow', {
        fromDate,
        untilDate,
        encounterId,
        jobUUID,
      });
    }
  } catch (error) {
    logger.error('Failed to get patient encounters', {
      error,
      fromDate,
      untilDate,
      patientId,
      jobUUID,
    });
    throw error;
  }

  return encounters;
}

/**
 * Handles patient privacy for printing encounters.
 *
 * @param selfPay - A boolean indicating whether the patient is self-paying.
 * @param releaseOfInformation - The release of information for the patient.
 * @param logger - The logger used for logging information.
 * @param pseudonymizedId - The pseudonymized ID of the patient.
 * @param ehrPatient - The patient data from the EHR.
 * @param jobUUID - The UUID of the job.
 */
const handlePatientPrivacy = (
  selfPay: boolean | undefined,
  releaseOfInformation: ReleaseOfInformation | undefined,
  logger: ContextedLogger,
  pseudonymizedId: any,
  ehrPatient: ActionTypes.PatientAction.Response,
  jobUUID: string,
) => {
  if (selfPay || (releaseOfInformation && releaseOfInformation !== ReleaseOfInformation.Y)) {
    logger.info('Rejecting patient jobs due to privacy reasons', {
      pseudonymizedId,
      ehrPatient,
      selfPay,
      releaseOfInformation,
      jobUUID,
    });

    throw new Error(DATA_RESTRICTED_ERROR_MESSAGE);
  }
};

/**
 * Fetches the feature flags related to printing encounters.
 * @returns An object containing the fetched feature flags.
 */
const fetchFeatureFlags = async () => {
  const shouldAllowUnlockedEncounterFF = await featureFlagsClient.getFlag({
    flagName: 'shouldAllowUnlockedEncounter',
    defaultValue: false,
  });
  const shouldReportEncountersNotLockedToDataSourceFF = await featureFlagsClient.getFlag({
    flagName: 'shouldReportEncountersNotLockedToDataSource',
    defaultValue: false,
  });
  const shouldUseAdditionalJobFiltersFF = await featureFlagsClient.getFlag({
    flagName: 'shouldUseAdditionalJobFilters',
    defaultValue: false,
  });
  return {
    shouldAllowUnlockedEncounterFF,
    shouldReportEncountersNotLockedToDataSourceFF,
    shouldUseAdditionalJobFiltersFF,
  };
};

/**
 * Handles the upload of printed encounter files to S3.
 * @param jobUUID - The UUID of the job.
 * @param files - An array of FileMetadata objects representing the printed files.
 * @param fileResults - An array to store the results of the file upload.
 * @param logger - The logger instance.
 * @param fromDate - The start date of the encounters.
 * @param untilDate - The end date of the encounters.
 * @param encounterId - The ID of the encounter.
 * @param patientId - The ID of the patient.
 */
const handleUploadEncounterPrintedFilesToS3 = async (
  jobUUID: string,
  files: FileMetadata[],
  fileResults: FileResult[],
  logger: ContextedLogger,
  fromDate: any,
  untilDate: any,
  encounterId: string,
  patientId: string,
) => {
  try {
    const writeToS3Params = { jobUUID, files };
    const encounterWrittenFilesResult = await writeToS3(writeToS3Params);
    fileResults.push(...encounterWrittenFilesResult);
    logger.info('successfully written encounter files result', {
      fromDate,
      untilDate,
      encounterId,
      patientId,
      filesResult: fileResults,
      jobUUID,
    });
  } catch (error) {
    logger.error('Failed to write encounter files to S3', {
      error,
      fromDate,
      untilDate,
      encounterId,
      patientId,
      jobUUID,
    });
    throw error;
  }
};

/**
 * Handles the printing of HTML and JSON files for a given encounter.
 * @param encounterData - The response containing the encounter data.
 * @param encounterId - The ID of the encounter.
 * @param requester - The requester information.
 * @param pseudonymizedId - The pseudonymized ID of the patient.
 * @param patientId - The ID of the patient.
 * @param ehrPatient - The response containing the EHR patient data.
 * @param logger - The logger instance.
 * @param jobUUID - The UUID of the job.
 * @returns An array of FileMetadata objects representing the printed files.
 */
const handlePrintHTMLAndJSONFiles = async (
  encounterData: ActionTypes.EncounterAction.Response,
  encounterId: string,
  requester: any,
  pseudonymizedId: any,
  patientId: string,
  ehrPatient: ActionTypes.PatientAction.Response,
  logger: ContextedLogger,
  jobUUID: string,
  fileResults: FileResult[],
): Promise<FileMetadata[]> => {
  const fileName = getFileName({
    date: encounterData.encounterDate!,
    time: encounterData.encounterTime!,
    encounterId,
    requester,
    pseudonymizedId,
  });
  const input = { encounterId, patientId };
  const { ...printEncounterResponse } = await adapterActionApi.printEncounter(input);

  const metadata = await convertMetadata(ehrPatient, encounterData);
  const jsonFile = createJsonFile({ fileName, data: metadata });
  const files = [jsonFile];

  const labsAndVitalsFilePath = await generateLabsAndVitalsFile({
    encounterId,
    patientId,
    fileName,
    metadata,
    jobUUID,
  });

  const shouldShareVitalsLabsFileFF = await featureFlagsClient.getFlag({
    flagName: 'shouldShareVitalsLabsFile',
    defaultValue: false,
    flagContext: { requester },
  });

  if (shouldShareVitalsLabsFileFF && labsAndVitalsFilePath) {
    logger.info('Sharing labs and vitals file', { labsAndVitalsFilePath, requester });
    fileResults.push({ path: labsAndVitalsFilePath });
  }

  if ('html' in printEncounterResponse) {
    const { html } = printEncounterResponse;

    const htmlFile = {
      name: fileName,
      data: generateBlobFromHtmlString(html),
      extension: FileExtension.HTML,
    };

    files.push(htmlFile);
  } else {
    const { failureType } = printEncounterResponse;
    logger.error('Failed to print HTML encounter', {
      encounterId,
      patientId,
      jobUUID,
      error: failureType,
      files,
    });
  }
  return files;
};

/**
 * Handles the errors that occur during the printing of encounters.
 * If there are encounter not locked errors, it checks if all encounters failed due to unlocked status.
 * If so, it throws an error if no files were printed, otherwise it logs a warning.
 * If there are other errors, it logs an error and throws an error with the file results.
 * @param printEncountersErrors - The array of print encounter errors.
 * @param encounters - The response containing the encounters.
 * @param fileResults - The array of file results.
 * @param logger - The logger instance.
 * @param fromDate - The start date of the encounters.
 * @param untilDate - The end date of the encounters.
 * @param patientId - The ID of the patient.
 * @param jobUUID - The UUID of the job.
 * @param type - The type of the job.
 * @param shouldReportEncountersNotLockedToDataSourceFF - A boolean indicating whether encounters not locked should be reported to the data source in multiple encounter flow
 */
const handlePrintEncountersErrors = (
  printEncountersErrors: PrintEncountersError[],
  encounters: Response,
  fileResults: FileResult[],
  logger: ContextedLogger,
  fromDate: any,
  untilDate: any,
  patientId: string,
  jobUUID: string,
  type: CdeJobType,
  shouldReportEncountersNotLockedToDataSourceFF: boolean,
) => {
  if (printEncountersErrors.length > 0) {
    const encounterNotLockedErrors = printEncountersErrors.filter(
      ([_, errorMessage]) => errorMessage === ENCOUNTER_NOT_LOCKED_ERROR_MESSAGE,
    );
    if (encounterNotLockedErrors) {
      logger.warning(
        `Some encounters failed to print due to unlocked status (${encounterNotLockedErrors.length}/${encounters.length}) jobType:${type}`,
        {
          fromDate,
          untilDate,
          patientId,
          errors: encounterNotLockedErrors,
          jobUUID,
        },
      );
      //only singleEncounter flow throws this error to server
      //multiple flow return success, while fileResults should be empty []
      if (
        encounterNotLockedErrors.length == encounters.length &&
        (type == CdeJobType.getSingleEncounter || shouldReportEncountersNotLockedToDataSourceFF)
      ) {
        throw new Error(ENCOUNTER_NOT_LOCKED_ERROR_MESSAGE);
      }
    }

    const notEncounterNotLockedErrors = printEncountersErrors.filter(
      ([_, errorMessage]) => errorMessage !== ENCOUNTER_NOT_LOCKED_ERROR_MESSAGE,
    );

    if (notEncounterNotLockedErrors.length > 0) {
      logger.error('Some encounters failed to print and is not recoverable will require retry', {
        fromDate,
        untilDate,
        failedEncountersSize: printEncountersErrors.length,
        errors: printEncountersErrors,
        jobUUID,
      });

      const printEncounterError = new Error(FAILED_TO_PRINT_ENCOUNTER_ERROR_MESSAGE);
      (printEncounterError as any).fileResults = fileResults;
      throw printEncounterError;
    }
  }
};
