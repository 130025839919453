import { ActionNames } from '@getvim/internal-vim-os-sdk/types';
import {
  NO_PATIENT_ERROR_MESSAGE,
  FIND_PATIENT_UNEXPECTED_ISSUE,
  INVALID_WORKER,
  GET_PATIENT_UNEXPECTED_ISSUE,
} from '../../../consts';
import { getLogger } from '../../../components/logger';
import { jobManager, sessionManager } from '../../../state';
import { JobsCacheManager } from '../../../utils/jobsCacheManager';
import { adapterActionApi } from '../../../api/adapter';
import { FindPatientResponse } from '../../../types';
import { patientEncountersHandler } from './logic';

const cache = new JobsCacheManager<object, string | object>();
const getPatientEncountersLogger = getLogger({ scope: 'get-patient-encounters-handler' });

export const getPatientEncountersHandler = async () => {
  const jobPatient = jobManager.get('patient');

  let ehrPatientId: string | undefined = undefined;
  let ehrPatient;

  ehrPatientId = cache.get(jobPatient) as string;

  if (!ehrPatientId) {
    const {
      isSuccess: isActionSuccessful,
      patientId,
      isInvalidWorker,
    }: FindPatientResponse = (await adapterActionApi.findPatient(jobPatient)) || {};

    getPatientEncountersLogger.info(`Got response for action: ${ActionNames.FIND_PATIENT}`, {
      ehrPatientId: patientId,
      isSuccess: isActionSuccessful,
      isInvalidWorker: isInvalidWorker,
    });

    if (!isActionSuccessful) {
      if (isInvalidWorker) {
        sessionManager.set({ invalidEhrSession: true });
        throw new Error(INVALID_WORKER);
      }
      throw new Error(FIND_PATIENT_UNEXPECTED_ISSUE);
    }

    if (isActionSuccessful && !patientId) {
      throw new Error(NO_PATIENT_ERROR_MESSAGE);
    }

    const foundPatientId: string = patientId as string;
    cache.set(jobPatient, foundPatientId);
    ehrPatientId = foundPatientId;
  }

  ehrPatient = cache.get(ehrPatientId);

  if (!ehrPatient) {
    const patient = await adapterActionApi.getPatient(ehrPatientId);

    getPatientEncountersLogger.info(`Got response for action: ${ActionNames.GET_PATIENT}`, {
      ehrPatient: patient,
    });
    if (!patient) throw new Error(GET_PATIENT_UNEXPECTED_ISSUE);

    cache.set(ehrPatientId, patient);

    ehrPatient = patient;
  } else {
    if (
      jobManager.get('vimPatientId') &&
      ehrPatient.vimPatientId !== jobManager.get('vimPatientId')
    ) {
      getPatientEncountersLogger.warning(
        `VimPatientId mismatch between cache and job metadata, cache: ${
          ehrPatient.vimPatientId
        }, jobManager: ${jobManager.get('vimPatientId')}, jobUuid: ${jobManager.get('jobUUID')}`,
      );
    }

    jobManager.set({ vimPatientId: ehrPatient.vimPatientId });
  }

  return await patientEncountersHandler({ ehrPatient });
};
