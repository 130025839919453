import { VimSDKProvider } from '@getvim/internal-vim-os-sdk/react';
import React, { useCallback, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { ThemeVariablesWrapper, themes } from '@getvim/components-hooks-use-theme';
import { FeatureFlagProvider, Team } from '@getvim/feature-flags-react';
import { ErrorBoundaryWrapper, Loader } from '@getvim/atomic-ui';
import { featureFlagsClient, queryClient } from './services';
import { initWorker } from './logic/init-worker';
import { AsyncContentWrapper } from './AsyncContentWrapper';
import { getLogger } from './components/logger';
import { App } from './components/App';
import './index.less';
import AsyncContentWrapperOld from './AsyncContentWrapperOld';
export const indexLogger = getLogger({ scope: 'CDE App index' });

const handleRenderError = ({ error }) =>
  indexLogger.error('Client application crashed due to render error', {
    error,
  });
const AppWithFeatureFlag: React.FC = () => {
  const mainLogger = getLogger({ scope: 'main' });
  const [shouldFixStartWithoutInitSDKBug, setShouldFixStartWithoutInitSDKBug] = useState(false);

  const fetchShouldFixStartWithoutInitSDKBugFF = useCallback(async () => {
    const shouldFixStartWithoutInitSDKBugFF = await featureFlagsClient.getFlag({
      flagName: 'shouldFixStartWithoutInitSDKBug',
      defaultValue: false,
      team: Team.CDE,
    });
    setShouldFixStartWithoutInitSDKBug(shouldFixStartWithoutInitSDKBugFF);
  }, []);

  useEffect(() => {
    fetchShouldFixStartWithoutInitSDKBugFF().catch((error) => {
      mainLogger.warning('Failed to fetch shouldFixStartWithoutInitSDKBugFF', { error });
    });
  }, [fetchShouldFixStartWithoutInitSDKBugFF, mainLogger]);

  return shouldFixStartWithoutInitSDKBug ? (
    <>
      <VimSDKProvider>
        <QueryClientProvider client={queryClient}>
          <FeatureFlagProvider featureFlagsClient={featureFlagsClient}>
            <ThemeVariablesWrapper theme={themes.vim}>
              <AsyncContentWrapper>
                <ErrorBoundaryWrapper
                  onRenderError={handleRenderError}
                  applicationDisplayName="Clinical Data Exchange"
                >
                  <React.StrictMode>
                    <App />
                  </React.StrictMode>
                </ErrorBoundaryWrapper>
              </AsyncContentWrapper>
            </ThemeVariablesWrapper>
          </FeatureFlagProvider>
        </QueryClientProvider>
      </VimSDKProvider>
    </>
  ) : (
    <>
      <VimSDKProvider>
        <QueryClientProvider client={queryClient}>
          <FeatureFlagProvider featureFlagsClient={featureFlagsClient}>
            <ThemeVariablesWrapper theme={themes.vim}>
              <AsyncContentWrapperOld promises={[initWorker()]} loader={<Loader type="dots" />}>
                <ErrorBoundaryWrapper
                  onRenderError={handleRenderError}
                  applicationDisplayName="Clinical Data Exchange"
                >
                  <React.StrictMode>
                    <App />
                  </React.StrictMode>
                </ErrorBoundaryWrapper>
              </AsyncContentWrapperOld>
            </ThemeVariablesWrapper>
          </FeatureFlagProvider>
        </QueryClientProvider>
      </VimSDKProvider>
    </>
  );
};

createRoot(document.getElementById('root')).render(<AppWithFeatureFlag />);
