import { ActionTypes } from '@getvim-os/types';
import { ActionNames } from '@getvim/internal-vim-os-sdk/types';
import { RunEhrActionCallback } from './useEhrAction';
import { FindPatientResponse, PatientDetails } from '../../types';
import {
  auditGetPatientAction,
  auditGetPatientEncountersAction,
  auditGetPatientEncounterDetails,
  auditGetPatientEncounterMetadata,
} from '../../logic/audit';
import { jobManager } from '../../state';
import { getLogger, logAction, logFinishAction } from '../../components/logger';

export enum AdapterErrorMessages {
  MORE_THAN_A_SINGLE_MATCH = 'More than a single match',
  EHR_SESSION_INACTIVE = 'Ehr session inactive',
}
export class AdapterActionApi {
  private adapterActionsApiLogger = getLogger({ scope: 'adapter-action-api' });

  private static runAction: RunEhrActionCallback;

  public static setRunActionCallback(callback: RunEhrActionCallback) {
    AdapterActionApi.runAction = callback;
  }

  getPatient = async (ehrPatientId: string): Promise<ActionTypes.PatientAction.Response> => {
    let success = false;
    const startActionTime = Date.now();
    logAction(ActionNames.GET_PATIENT);

    try {
      const input = {
        patientId: ehrPatientId,
      };
      const patient: ActionTypes.PatientAction.Response = await AdapterActionApi.runAction(
        ActionNames.GET_PATIENT,
        input,
      );

      if (
        jobManager.get('vimPatientId') &&
        patient.vimPatientId !== jobManager.get('vimPatientId')
      ) {
        this.adapterActionsApiLogger.warning(
          `VimPatientId mismatch between GetPatient action and job metadata, cache: ${
            patient.vimPatientId
          }, jobManager: ${jobManager.get('vimPatientId')}, jobUuid: ${jobManager.get('jobUUID')}`,
        );
      }
      jobManager.set({ vimPatientId: patient.vimPatientId });

      success = true;
      return patient;
    } catch (error) {
      success = false;
      throw error;
    } finally {
      logFinishAction(ActionNames.GET_PATIENT, startActionTime);
      await auditGetPatientAction({ success, jobContext: jobManager.getAll() });
    }
  };

  findPatient = async (patient: PatientDetails): Promise<FindPatientResponse> => {
    const startActionTime = Date.now();
    logAction(ActionNames.FIND_PATIENT);

    const data: ActionTypes.FindPatientAction.Input = {
      demographics: {
        firstName: patient?.firstName,
        lastName: patient?.lastName,
        dateOfBirth: patient?.dateOfBirth,
        address: { zipCode: patient?.zipCode },
      },
      insurance: { memberId: patient?.memberId },
      patientId: patient?.mrn,
    };

    try {
      const result = await AdapterActionApi.runAction(ActionNames.FIND_PATIENT, data);
      if (result && result.patientId) {
        this.adapterActionsApiLogger.info(
          `Finished ${ActionNames.FIND_PATIENT} with success, patient found!`,
          { data, result },
        );
        return { isSuccess: true, patientId: result.patientId };
        //runtime flow, in case of session inactive or more than single match will return result with result.error information
      } else if (result && result.error) {
        //error of runtime - this should be deprecated, we should track this log in the logs and see it doesnt happen
        this.adapterActionsApiLogger.info(
          `Action ${ActionNames.FIND_PATIENT} finished with error but didn't throw it!`,
          { data, result },
        );
        throw result.error;
      } else {
        //result = null, patient was not found
        this.adapterActionsApiLogger.info(
          `Finished ${ActionNames.FIND_PATIENT} with success, but no patient found`,
          { data, result },
        );
        return { isSuccess: true };
      }
      //vimOs flow, in case of session inactive or more than single match will throw error with error.data information
    } catch (error: any) {
      return await this.handleErrorOnAdapterAction(data, error);
    } finally {
      logFinishAction(ActionNames.FIND_PATIENT, startActionTime);
    }
  };

  checkIsMoreThanSingleMatch = (errorMessage: any) => {
    if (!errorMessage) return false;
    const message_components = errorMessage.split(':');
    return message_components[1]?.includes(AdapterErrorMessages.MORE_THAN_A_SINGLE_MATCH) ?? false;
  };

  checkIsSessionInactive = (errorMessage: any) => {
    if (!errorMessage) return false;
    const message_components = errorMessage.split(':');
    return message_components[1]?.includes(AdapterErrorMessages.EHR_SESSION_INACTIVE) ?? false;
  };

  /*
  responsible to identify the specific error that happened on the adapter
  and return the correct response to the client
  special treat to session_inactive and to more_than_single_match
  @param data: any - input cde provided to the adapter
  @param error: any - the detailed error returned from the adapter
  */
  handleErrorOnAdapterAction = (data: any, error: any) => {
    const errMessageToParse = error.data ? error.data : error.message;
    this.adapterActionsApiLogger.info(
      `Going to try figure out failure reason of ${ActionNames.FIND_PATIENT}`,
      { errMessageToParse },
    );
    const isMoreThanSingleMatch = this.checkIsMoreThanSingleMatch(errMessageToParse);
    if (isMoreThanSingleMatch) {
      this.adapterActionsApiLogger.info(
        `Finished ${ActionNames.FIND_PATIENT} with error more_than_single_match`,
        { data, error },
      );
      return { isSuccess: true };
    }
    const isSessionInactive = this.checkIsSessionInactive(errMessageToParse);
    if (isSessionInactive) {
      this.adapterActionsApiLogger.info(
        `Finished ${ActionNames.FIND_PATIENT} with error session_inactive`,
        { data, error },
      );
      return { isSuccess: false, isInvalidWorker: true };
    } else {
      this.adapterActionsApiLogger.error(
        `Finished ${ActionNames.FIND_PATIENT} with error not recognized`,
        { data, error },
      );
      return { isSuccess: false };
    }
  };

  getEncounterData = async ({
    encounterId,
    patientId,
  }: ActionTypes.EncounterAction.Input): Promise<ActionTypes.EncounterAction.Response> => {
    const startActionTime = Date.now();
    let getEncounterDataSuccess = false;
    const jobContext = jobManager.getAll();
    logAction(ActionNames.GET_ENCOUNTER_DATA);
    try {
      const input = {
        encounterId,
        patientId,
      };
      const encounterData = await AdapterActionApi.runAction(ActionNames.GET_ENCOUNTER_DATA, input);
      getEncounterDataSuccess = true;

      return encounterData;
    } finally {
      logFinishAction(ActionNames.GET_ENCOUNTER_DATA, startActionTime);

      await Promise.all([
        auditGetPatientEncounterDetails({ success: getEncounterDataSuccess, jobContext }),
        auditGetPatientEncounterMetadata({ success: getEncounterDataSuccess, jobContext }),
      ]);
    }
  };

  printEncounter = async ({
    encounterId,
    patientId,
  }: ActionTypes.PrintEncounterAction.InputV2): Promise<ActionTypes.PrintEncounterAction.ResponseV2> => {
    const startActionTime = Date.now();
    logAction(ActionNames.PRINT_ENCOUNTER);

    try {
      const input = {
        encounterId,
        patientId,
        pdfUploadUrl: 'https://dummy-url', //dummy url just because it is a must in the adapter
      };
      return await AdapterActionApi.runAction(ActionNames.PRINT_ENCOUNTER, input);
    } finally {
      logFinishAction(ActionNames.PRINT_ENCOUNTER, startActionTime);
    }
  };

  getPatientEncounters = async ({
    patientId,
  }: ActionTypes.PatientEncountersAction.Input): Promise<ActionTypes.PatientEncountersAction.Response> => {
    let success = false;
    const startActionTime = Date.now();
    logAction(ActionNames.GET_PATIENT_ENCOUNTERS);
    const jobContext = jobManager.getAll();

    try {
      const { fromDate, untilDate } = jobManager.getAll();

      const input = { patientId, fromDate, untilDate };
      const patientEncounters = await AdapterActionApi.runAction(
        ActionNames.GET_PATIENT_ENCOUNTERS,
        input,
      );

      success = true;

      return patientEncounters;
    } catch (error) {
      success = false;
      throw error;
    } finally {
      logFinishAction(ActionNames.GET_PATIENT_ENCOUNTERS, startActionTime);
      await auditGetPatientEncountersAction({ success, jobContext });
    }
  };

  getEncounterVitals = async ({
    encounterId,
    patientId,
  }: ActionTypes.GetEncounterVitalsAction.Input): Promise<ActionTypes.GetEncounterVitalsAction.Response> => {
    const startActionTime = Date.now();
    logAction(ActionNames.GET_ENCOUNTER_VITALS);

    try {
      const input: ActionTypes.GetEncounterVitalsAction.Input = {
        encounterId,
        patientId,
      };
      return await AdapterActionApi.runAction(ActionNames.GET_ENCOUNTER_VITALS, input);
    } finally {
      logFinishAction(ActionNames.GET_ENCOUNTER_VITALS, startActionTime);
    }
  };

  getEncounterLabResults = async ({
    encounterId,
    patientId,
  }: ActionTypes.GetEncounterLabResultsAction.Input): Promise<ActionTypes.GetEncounterLabResultsAction.Response> => {
    const startActionTime = Date.now();
    logAction(ActionNames.GET_ENCOUNTER_LAB_RESULTS);

    try {
      const input: ActionTypes.GetEncounterLabResultsAction.Input = {
        encounterId,
        patientId,
      };
      return await AdapterActionApi.runAction(ActionNames.GET_ENCOUNTER_LAB_RESULTS, input);
    } finally {
      logFinishAction(ActionNames.GET_ENCOUNTER_LAB_RESULTS, startActionTime);
    }
  };
}
