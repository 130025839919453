import React from 'react';

const SizeIconLarge: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="15"
    height="11"
    viewBox="0 0 15 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 1.125C4 0.50368 4.50368 0 5.125 0H13.25C13.8713 0 14.375 0.50368 14.375 1.125V9.875C14.375 10.4963 13.8713 11 13.25 11H5.125C4.50368 11 4 10.4963 4 9.875V1.125ZM5.125 1C5.05596 1 5 1.05596 5 1.125V9.875C5 9.94404 5.05596 10 5.125 10H13.25C13.319 10 13.375 9.94404 13.375 9.875V1.125C13.375 1.05596 13.319 1 13.25 1H5.125Z"
      fill="#001C36"
    />
    <mask
      id="mask0_6887_3601"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="5"
      height="11"
    >
      <rect width="5" height="11" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_6887_3601)">
      <rect
        x="0.5"
        y="0.5"
        width="8"
        height="10"
        rx="0.5"
        stroke="#001C36"
        strokeLinejoin="bevel"
        strokeDasharray="2 1"
      />
    </g>
  </svg>
);

export default SizeIconLarge;
