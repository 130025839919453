import React, { useState, useEffect } from 'react';
import { initWorker } from './logic/init-worker';
import { Loader } from '@getvim/atomic-ui';
import { organizationManager } from './state';
import { useCdeConfig } from './hooks/useCdeConfig';

export const AsyncContentWrapper: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { organization } = useCdeConfig();

  useEffect(() => {
    if (organization) {
      organizationManager.set({ organizationId: organization.identifiers?.id });
    }
    initWorker()
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false)); // added for lint will never threw error since initWorker is not throwing
  }, [organization]);

  if (isLoading) {
    return <Loader type="dots" />;
  }

  return <>{children}</>;
};
