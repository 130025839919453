import { PatientDetails } from './patient';

export enum CdeJobType {
  getPatientEncounters = 'getPatientEncounters',
  getSingleEncounter = 'getSingleEncounter',
}

export interface JobAdditionalFilters {
  shouldNotIncludeTelephoneEncounters?: boolean;
}

export interface JobDetails {
  jobUUID: string;
  type: CdeJobType;
  fromDate: string;
  untilDate: string;
  requester: string;
  encounterId?: string;
  batchOperationId: number;
  patient: PatientDetails;
  additionalFilters: JobAdditionalFilters;
  vimPatientId?: string;
}
