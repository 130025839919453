import { ScopedLogger } from '@getvim/vim-connect-logger';
import { FileResult, JobDetails } from '../../types';
import { JobFinishStatus } from '../../api/bff';

export const logCDERequestsReport = ({
  job,
  jobLogger,
  status,
  results,
  isErrorValid,
  originalErrorMessage,
  parsedErrorMessage,
}: {
  job: JobDetails;
  jobLogger: ScopedLogger;
  status: JobFinishStatus;
  results?: FileResult[];
  isErrorValid?: boolean;
  originalErrorMessage?: any;
  parsedErrorMessage?: string;
}) => {
  if (!job) {
    throw new Error('Tried reporting CDE job without a job');
  }

  jobLogger.info('CDE job processing report', {
    ...(job.patient.clientInternalRecordId !== undefined && {
      chartId: job.patient.clientInternalRecordId,
    }),
    ...(job.requester !== undefined && { dataRequester: job.requester }),
    ...(job.batchOperationId !== undefined && { batchOperationId: job.batchOperationId }),
    ...(status !== undefined && { status }),
    ...(job.encounterId !== undefined && { encounterId: job.encounterId }),
    ...(results !== undefined && { jobresults: results }),
    ...(isErrorValid !== undefined && { isErrorValid }),
    ...(originalErrorMessage !== undefined && { originalErrorMessage }),
    ...(parsedErrorMessage !== undefined && { parsedErrorMessage }),
  });
};
