import { AppToOsLogMessage, LogMetadata } from '@getvim/vim-os-api';

export class SdkLogger {
  private sendMessageToSdk: (message: AppToOsLogMessage) => void;
  constructor() {
    const messageChannel = new MessageChannel();
    messageChannel.port1.start();
    window.parent.postMessage(
      {
        type: 'INITIALIZE_LOGGER',
      },
      '*',
      [messageChannel.port2],
    );
    this.sendMessageToSdk = (message: AppToOsLogMessage) => {
      messageChannel.port1.postMessage(message);
    };
  }

  public info = (message: string, data?: Record<string, any>): void => {
    this.sendMessageToSdk({
      type: 'LOG',
      payload: {
        message,
        data,
        level: 'info',
      },
    });
  };

  public warn = (message: string, data?: Record<string, any>): void => {
    this.sendMessageToSdk({
      type: 'LOG',
      payload: {
        message,
        data,
        level: 'warning',
      },
    });
  };

  public error = (message: string, data?: Record<string, any>, error?: Error): void => {
    this.sendMessageToSdk({
      type: 'LOG',
      payload: {
        message,
        data,
        error,
        level: 'error',
      },
    });
  };

  public updateMetadata = (metadata: LogMetadata) => {
    this.sendMessageToSdk({
      type: 'UPDATE_METADATA',
      payload: metadata,
    });
  };
}
