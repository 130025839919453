import { CPT, Diagnosis, Provider } from '..';

const EncounterTypeValues = {
  InOffice: 'In office',
  OutOfOffice: 'Out of office',
  Video: 'Video',
  Telephone: 'Telephone',
  Other: 'Other',
} as const;

export type EncounterType = (typeof EncounterTypeValues)[keyof typeof EncounterTypeValues];

export enum EncounterTypeEnum {
  InOffice = 'In office',
  OutOfOffice = 'Out of office',
  Video = 'Video',
  Telephone = 'Telephone',
  Other = 'Other',
}

export interface Encounter {
  encounterId?: string;
  provider?: Provider;
  assessments?: Diagnosis[];
  assessmentsGeneralNotes?: string;
  isLocked?: boolean;
  encounterDate?: string;
  encounterType?: EncounterType;

  /* Soap Fields */
  encounterNotes?: {
    generalNotes?: string;
  };
  subjective?: {
    generalNotes?: string;
    chiefComplaintNotes?: string;
    historyOfPresentIllnessNotes?: string;
    reviewOfSystemsNotes?: string;
  };
  objective?: {
    generalNotes?: string;
    physicalExamNotes?: string;
  };
  soapAssessments?: {
    generalNotes?: string;
    diagnosisCodes?: Diagnosis[];
  };
  plan?: {
    generalNotes?: string;
    procedureCodes?: CPT[];
  };
  patientInstructions?: {
    generalNotes?: string;
  };
}
