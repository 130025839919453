import { ActionTypes, Specialty } from '@getvim-os/types';
import { GetFileNameParams } from './types';

export const convertMetadata = async (
  patient: ActionTypes.PatientAction.Response,
  encounter: ActionTypes.EncounterAction.Response,
) => {
  const { patientId, contact_info, demographics, insurance, pcp } = patient;
  const { memberId, ehrInsurance, externalMrn } = insurance || {};
  const {
    providerEhrId: pcpProviderEhrId,
    npi: pcpNpi,
    demographics: pcpDemographics,
    organizations: pcpOrganizations,
    specialty: pcpSpecialty,
    visitTypes: pcpVisitTypes,
  } = pcp || {};
  const { encounterId: encounterEhrId, encounterDate, provider } = encounter;

  const {
    providerEhrId,
    demographics: providerDemographics,
    npi: providerNpi,
    degree: providerCredentials,
    specialty: providerSpecialty,
  } = provider ?? {};

  const {
    firstName: providerFirstName,
    lastName: providerLastName,
    middleName: providerMiddleName,
  } = providerDemographics ?? {};

  return {
    encounter: {
      ehrId: encounterEhrId,
      date: encounterDate,
    },
    ...(provider && {
      provider: {
        ehrId: providerEhrId,
        npi: providerNpi,
        credentials: providerCredentials,
        specialty: await filterOutVimTaxonomies(providerSpecialty ?? []),
        demographics: {
          firstName: providerFirstName,
          lastName: providerLastName,
          middleName: providerMiddleName,
        },
      },
    }),
    patient: {
      ehrId: patientId,
      contact_info,
      demographics,
      insurance: { memberId, ehrInsurance, externalMrn },
      pcp: {
        ehrId: pcpProviderEhrId,
        npi: pcpNpi,
        demographics: pcpDemographics,
        organizations: pcpOrganizations,
        specialty: await filterOutVimTaxonomies(pcpSpecialty ?? []),
        visitTypes: pcpVisitTypes,
      },
    },
  };
};

export const generateBlobFromHtmlString = (htmlString: string) =>
  new Blob([htmlString], { type: 'text/html' });

export const getFileName = ({
  date,
  time,
  encounterId,
  requester,
  pseudonymizedId,
}: GetFileNameParams) =>
  `${requester}_encounter_${pseudonymizedId}_${encounterId}_${date}T${time}`.replaceAll(':', '-');

const filterOutVimTaxonomies = async (providerSpecialty: Specialty[]): Promise<Specialty[]> => {
  if (providerSpecialty.length === 0) return [];

  const vimTaxonomyPrefix = 'BKMD';
  return providerSpecialty.map((specialty) => {
    return {
      ...specialty,
      taxonomies: specialty.taxonomies?.filter(
        (taxonomy) => !taxonomy.startsWith(vimTaxonomyPrefix),
      ),
    };
  });
};
